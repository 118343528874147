import {ResourcesConfig} from 'aws-amplify'
import {AmplifyOutputs} from 'aws-amplify/adapter-core'

interface OAuthConfig {
  domain: string
  scope: string[]
  redirectSignIn: string
  redirectSignOut: string
  responseType: string
}

interface CognitoPasswordProtectionSettings {
  passwordPolicyMinLength: number
  passwordPolicyCharacters: string[]
}

export interface AmplifyConfig {
  aws_project_region: string
  aws_appsync_graphqlEndpoint: string
  aws_appsync_region: string
  aws_appsync_authenticationType: string
  aws_appsync_apiKey: string
  aws_cognito_identity_pool_id: string
  aws_cognito_region: string
  aws_user_pools_id: string
  aws_user_pools_web_client_id: string
  oauth: OAuthConfig
  federationTarget: string
  aws_cognito_username_attributes: string[]
  aws_cognito_social_providers: string[]
  aws_cognito_signup_attributes: string[]
  aws_cognito_mfa_configuration: string
  aws_cognito_mfa_types: string[]
  aws_cognito_password_protection_settings: CognitoPasswordProtectionSettings
  aws_cognito_verification_mechanisms: string[]
  aws_user_files_s3_bucket: string
  aws_user_files_s3_bucket_region: string
}

export function getAmplifyConfig(amplifyConfig: AmplifyConfig) {
  console.log(
    'process.env.NEXT_PUBLIC_SOCIAL_PROVIDER_REDIRECT_SIGN_IN_URL',
    process.env.NEXT_PUBLIC_SOCIAL_PROVIDER_REDIRECT_SIGN_IN_URL,
  )
  console.log(
    'process.env.NEXT_PUBLIC_SOCIAL_PROVIDER_REDIRECT_SIGN_OUT_URL',
    process.env.NEXT_PUBLIC_SOCIAL_PROVIDER_REDIRECT_SIGN_OUT_URL,
  )
  return {
    ...amplifyConfig,
    oauth: {
      ...amplifyConfig.oauth,
      redirectSignIn:
        process.env.NEXT_PUBLIC_SOCIAL_PROVIDER_REDIRECT_SIGN_IN_URL ??
        'https://localhost:3000/',
      redirectSignOut:
        process.env.NEXT_PUBLIC_SOCIAL_PROVIDER_REDIRECT_SIGN_OUT_URL ??
        'https://localhost:3000/',
    },
  } as AmplifyOutputs | ResourcesConfig
}
