// @ts-nocheck
'use client'

import {fetchUserAttributes, getCurrentUser} from 'aws-amplify/auth'
import {createContext, ReactNode, useLayoutEffect} from 'react'

import {algoliaInsightsService} from '@atorie/api/algolia-insights'
import {User, userService} from '@atorie/api/users'
import {useAuthUserQuery} from '@atorie/hooks'
import {Hub} from '@aws-amplify/core'

interface AuthContextValue {
  user: User | null
}

export const AuthContext = createContext<AuthContextValue>({
  user: null,
})

export interface AuthProviderProps {
  children: React.ReactNode
}

export function AuthProvider({children}: AuthProviderProps) {
  const {data: user, refetch} = useAuthUserQuery()

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const removeListener = Hub.listen('auth', async ({payload}) => {
      switch (payload.event) {
        case 'signInWithRedirect':
          if (localStorage.getItem('guest_id')) {
            localStorage.setItem('signed_in_with_redirect', 'true')
          }
          break
        case 'signInWithRedirect_failure':
          break
        case 'signedIn':
          if (
            localStorage.getItem('guest_id') &&
            localStorage.getItem('signed_in_with_redirect')
          ) {
            try {
              const currentCognitoUser = await getCurrentUser()
              console.log('currentCognitoUser', currentCognitoUser)
              const cognitoUserAttributes = await fetchUserAttributes()
              console.log('cognitoUserAttributes', cognitoUserAttributes)
              const guestId = localStorage.getItem('guest_id') as string
              const localGuestUser = await userService.getGuestUser(guestId)

              await userService.updateGuestUser(guestId, {
                ...localGuestUser,
                auth_id: currentCognitoUser.userId,
                auth_provider: 'cognito',
                email: cognitoUserAttributes.email ?? localGuestUser.email,
              })
              localStorage.removeItem('guest_id')
              localStorage.removeItem('signed_in_with_redirect')
            } catch (error) {
              console.log('error updating guest user:', error)
            }
          }
          break
        case 'customOAuthState':
          break
      }
      refetch()
    })

    return () => {
      removeListener()
    }
  }, [refetch])

  algoliaInsightsService.initAlgoliaInsights(user?.id ?? '')

  return (
    <AuthContext.Provider
      value={{
        user: user ?? null,
      }}
    >
      {children as React.ReactNode}
    </AuthContext.Provider>
  )
}

export default AuthProvider
